<template>

  <div>
    <b-table
        responsive
        hover
        :items="repairs"
        :fields="allFields"
        :filter="filter"
    >
      <template v-slot:head()="data">
        <div v-if="!data.field.sortable">{{data.label}}</div>
        <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
      </template>

      <template v-slot:cell(referenceNumber)="data">
        <router-link :to="data.item.link">{{ data.item.referenceNumber }}</router-link>
      </template>

      <template v-slot:cell(assignedOn)="data">
        {{ data.item.assignedOn | formatDate }}
      </template>
    </b-table>
  </div>

</template>

<script>
export default {
  name: "RepairerViewRepairsAssigned",
  props: {
    repairs: Array,
  },
  data(){
    return {
      filter: '',
      allFields: [
        {label: "Reference Nbr", key: "referenceNumber", sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: "Vehicle Owner", key: "owner", sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: "Owner Type", key: "ownerType", sortable: true, tdClass: 'clickable ', thClass: ''},
        {label: "Insurer", key: "insurer", sortable: true, tdClass: 'clickable ', thClass: ''},
        {label: "Make", key: "make", sortable: true, tdClass: 'clickable', thClass: ''},
        {label: "Model", key: "model", sortable: true, tdClass: 'clickable', thClass: ''},
        {label: "Date Assigned", key: "assignedOn", sortable: false, tdClass: 'clickable ', thClass: ''},
      ],
    }
  },
}
</script>

<style scoped>

</style>
