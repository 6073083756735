<script>
import BlockAddress from '@/components/block-address';
import {mapGetters} from "vuex";
import multiselect from 'vue-multiselect';

export default {
  components: {
    multiselect,
    BlockAddress,
  },
  name: 'RepairerViewDetails',
  props: {
    repairer: {
      type: Object,
      default: () => ({
        location: '',
        levelUnitLot: '',
        streetName: '',
        suburb: '',
        state: '',
        postcode: '',
        country: 'AU',
      })
    },
  },
  computed: {
    ...mapGetters({
      addressStates: 'addressStates',
      addressCountries: 'addressCountries',
    }),
    postalState: {
      get(){
        let o = null;
        this.addressStates.forEach(s => {
          if(this.repairer.postalState == s.key){
            o = s;
          }
        })
        return o;
      },
      set(val){
        this.repairer.postalState = val.key;
      }
    },
    postalCountry: {
      get(){
        let o = null;
        this.addressCountries.forEach(s => {
          if(this.repairer.postalCountry == s.key){
            o = s;
          }
        })
        return o;
      },
      set(val){
        this.repairer.postalCountry = val.key;
      }
    },
    repairerName: {
      get(){
        if(this.repairer.businessName == 'New Repairer'){
          return '';
        }
        return this.repairer.businessName;
      },
      set(val){
        if(val === ''){
          this.repairer.businessName = 'New Repairer';
        } else {
          this.repairer.businessName = val;
        }
      }
    },
  },
  mounted() {
    console.log(this.addressStates)
  }
}
</script>

<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card">
        <div class="card-header bg-navyblue">
          <strong>Details</strong>
        </div>
        <div class="card-block bg-white">

          <div class="row">
            <div class="col-md-6">

              <div class="form-group row">
                <label for="owner" class="col-sm-4 col-4 col-form-label">Business Name</label>
                <div class="col-sm-8 col-8">
                  <input type="text" class="form-control" v-model="repairerName">
                </div>
              </div>

              <div class="form-group row">
                <label for="owner" class="col-sm-4 col-4 col-form-label">Contact Nbr</label>
                <div class="col-sm-8 col-8">
                  <input type="text" class="form-control" v-model="repairer.phone">
                </div>
              </div>

              <div class="form-group row">
                <label for="owner" class="col-sm-4 col-4 col-form-label">Email</label>
                <div class="col-sm-8 col-8">
                  <input type="text" class="form-control" v-model="repairer.email">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Set as Primary Email</label>
                <div class="col-sm-8 col-8">

                  <label class="form-check-inline radio">
                    <input v-model="repairer.isEmailPrimary"
                           class="form-check-input" type="radio"
                           :value="true">
                    <span class="icon"><i class='bx bx-check'></i></span>
                    <span class="text">Yes</span>
                  </label>

                  <label class="form-check-inline radio">
                    <input v-model="repairer.isEmailPrimary"
                           class="form-check-input" type="radio"
                           :value="false">
                    <span class="icon"><i class='bx bx-check'></i></span>
                    <span class="text">No</span>
                  </label>

                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Search Address</label>
                <div class="col-sm-8 col-8">
                  <input type="text" v-model="repairer.postalAddress" class="form-control">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Lot / Unit / Lvl</label>
                <div class="col-sm-8 col-8">
                  <input type="text" v-model="repairer.postalLevelUnitLot" class="form-control">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Street Name / Nbr</label>
                <div class="col-sm-8 col-8">
                  <input type="text" v-model="repairer.postalStreet" class="form-control">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Suburb</label>
                <div class="col-sm-8 col-8">
                  <input type="text" v-model="repairer.postalSuburb" class="form-control">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">State</label>
                <div class="col-sm-8 col-8">
                  <multiselect
                      v-model="postalState"
                      :options="addressStates"
                      track-by="key"
                      label="value"
                      :showLabels="false"
                      :option-height="29"
                      :max-height="203"
                      :close-on-select="true"
                      :allow-empty="false"
                      placeholder="Select state"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Postcode</label>
                <div class="col-sm-8 col-8">
                  <input type="text" v-model="repairer.postalPostcode" class="form-control">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Country</label>
                <div class="col-sm-8 col-8">
                  <multiselect
                      v-model="postalCountry"
                      :options="addressCountries"
                      track-by="key"
                      label="value"
                      :showLabels="false"
                      :option-height="29"
                      :max-height="203"
                      :close-on-select="true"
                      :allow-empty="false"
                      placeholder="Select country"
                  />
                </div>
              </div>

            </div>

            <div class="col-md-6">

              <div class="form-group row">
                <label for="owner" class="col-sm-4 col-4 col-form-label">MVRL License</label>
                <div class="col-sm-8 col-8">
                  <input type="text" class="form-control" v-model="repairer.licenseNumber">
                </div>
              </div>

              <div class="form-group row">
                <label for="owner" class="col-sm-4 col-4 col-form-label">ABN</label>
                <div class="col-sm-8 col-8">
                  <input type="text" class="form-control" v-model="repairer.abn">
                </div>
              </div>

              <div class="form-group row">
                <label for="owner" class="col-sm-4 col-4 col-form-label">QPS</label>
                <div class="col-sm-8 col-8">
                  <input type="text" class="form-control" v-model="repairer.qps">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Repairer Type</label>
                <div class="col-sm-8 col-8">

                  <label class="form-check radio">
                    <input v-model="repairer.type"
                           class="form-check-input" type="radio"
                           value="internal">
                    <span class="icon"><i class='bx bx-check'></i></span>
                    <span class="text">Internal</span>

                  </label>

                  <label class="form-check radio">
                    <input v-model="repairer.type"
                           class="form-check-input" type="radio"
                           value="subcontractor">
                    <span class="icon"><i class='bx bx-check'></i></span>
                    <span class="text">Subcontractor</span>
                  </label>

                  <label class="form-check radio">
                    <input v-model="repairer.type"
                           class="form-check-input" type="radio"
                           value="external">
                    <span class="icon"><i class='bx bx-check'></i></span>
                    <span class="text">External</span>
                  </label>

                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card">
        <div class="card-header bg-navyblue">
          <strong>Active</strong>
        </div>
        <div class="card-block bg-white">

          <div class="form-group row">
            <label class="col-sm-4 col-4 col-form-label">Active</label>
            <div class="col-sm-8 col-8">
              <label class="form-check-inline radio">
                <input v-model="repairer.active"
                       class="form-check-input" type="radio"
                       :value="true">
                <span class="icon"><i class='bx bx-check'></i></span>
                <span class="text">Yes</span>

              </label>
              <label class="form-check-inline radio">
                <input v-model="repairer.active"
                       class="form-check-input" type="radio"
                       :value="false">
                <span class="icon"><i class='bx bx-check'></i></span>
                <span class="text">No</span>
              </label>
            </div>
          </div>

        </div>
      </div>

      <div class="card">
        <div class="card-header bg-navyblue">
          <strong>Rights</strong>
        </div>
        <div class="card-block bg-white">

          <div class="form-group row">
            <label class="col-sm-4 col-4 col-form-label">Assign Guest Access</label>
            <div class="col-sm-8 col-8">
              <label class="form-check-inline radio">
                <input v-model="repairer.guestAccess"
                       class="form-check-input" type="radio"
                       :value="true">
                <span class="icon"><i class='bx bx-check'></i></span>
                <span class="text">Yes</span>

              </label>
              <label class="form-check-inline radio">
                <input v-model="repairer.guestAccess"
                       class="form-check-input" type="radio"
                       :value="false">
                <span class="icon"><i class='bx bx-check'></i></span>
                <span class="text">No</span>
              </label>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>

.col-form-label {
  padding: 8px 0;
}

</style>
